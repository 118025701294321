import React from "react";
import {
  DashboardIcon,
  LogoutIcon,
  MoneyIcon,
  StarIcon,
  ShopIcon,
  ProductIcon,
  CustomersIcon,
  DiscountIcon,
  CouponIcon,
  SettingsIcon,
} from "../components/Icons";
import { translator } from "../translation";

export const links = [
  {
    title: translator.groupLinks[0].name,
    links: [
      {
        name: translator.groupLinks[0].links[0].name,
        icon: <DashboardIcon />,
      },
    ],
  },

  {
    title: translator.groupLinks[1].name,
    links: [
      /*       //BsChatLeft
      {
        name: "chat",
        arName: "الرسائل",
        icon: <BsChatLeft />,
      }, */
      {
        name: translator.groupLinks[1].links[0].name,
        icon: <ShopIcon />,
      },
      {
        name: translator.groupLinks[1].links[1].name,
        icon: <ProductIcon />,
      },
      {
        name: translator.groupLinks[1].links[2].name,
        icon: <CustomersIcon />,
      },
      {
        name: translator.groupLinks[1].links[3].name,
        icon: <DiscountIcon />,
      },
      {
        name: translator.groupLinks[1].links[4].name,
        icon: <CouponIcon />,
      },
      {
        name: translator.groupLinks[1].links[6].name,
        icon: <CustomersIcon />,
      },
      {
        name: translator.groupLinks[1].links[5].name,
        icon: <SettingsIcon />,
      },
      {
        name: translator.groupLinks[1].links[7].name,
        icon: <LogoutIcon />,
      },
    ],
  },
];

export const earningData = [
  {
    icon: <ShopIcon />,
    title: translator.dashboard.cards[0],
    dataKey: "orders",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "red-600",
    link: "/orders/",
  },
  {
    icon: <ProductIcon />,
    title: translator.dashboard.cards[1],
    dataKey: "products",
    iconColor: "rgb(228, 106, 118)",
    iconBg: "rgb(255, 244, 229)",
    pcColor: "green-600",
    link: "/products/",
  },
  {
    icon: <MoneyIcon />,
    title: translator.dashboard.cards[2],
    dataKey: "sales",
    render: (v) => v?.toFixed(2),
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
    pcColor: "green-600",
    link: "/orders/",
  },
  {
    icon: <StarIcon />,
    title: translator.dashboard.cards[3],
    dataKey: "reviews",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
    pcColor: "red-600",
    link: "/products/",
  },
];

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];
