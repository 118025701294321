import { useEffect, useState } from "react";
import { useGET, usePOST, useUPDATE, useDELETE } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

export const useProducts = (id = false) => {
  const [products, setProducts] = useState([]);
  const [form, setForm] = useState({
    images: [],
    title: "",
    category: "",
    quantity: "",
    price: "",
    buy_price: "",
    description: "",
    packs: [],
  });
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleNotification, user, trackerID } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    useGET(`vendor/products/${id ? id : ""}`, {
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      if (res.type == "success") {
        if (!id) setProducts(res.data);
        if (id) {
          setForm({
            id: res.data[0][0].id,
            images: res.data[1],
            title: res.data[0][0]?.title,
            quantity: res.data[0][0]?.in_stock,
            category: res.data[0][0]?.category__id,
            price: res.data[0][0]?.price,
            buy_price: res.data[0][0]?.buy_price,
            description: res.data[0][0]?.description,
            packs: res.data[2],
          });
        }
      }
      if (res.type == "error") handleNotification(res);
    });
  }, [refresh]);

  const handleData = (data) => {
    const formData = new FormData();
    const packs = data.packs.filter((pack) => pack.id != undefined);
    const newPacks = data.packs.filter((pack) => pack.id == undefined);
    data.packs = packs.map((pack) => pack.id);
    const images = data.images.filter((image) => image.id != undefined);
    const newImages = data.images.filter((image) => image.id == undefined);
    data.images = images.map((image) => image.id);

    newPacks.map((pack, i) => {
      formData.append(`pack[${i}]`, pack.image);
      formData.append(`title[${i}]`, JSON.stringify(pack.title));
      formData.append(`quantity[${i}]`, JSON.stringify(pack.quantity));
    });
    newImages.map((image, i) => {
      formData.append(`image[${i}]`, image.file);
    });
    formData.append("data", JSON.stringify(data));
    return formData;
  };

  const createProduct = (data) => {
    setLoading(true);
    const form = handleData(data);
    usePOST("vendor/products/", {
      form: form,
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      if (res.type == "success") {
        navigate("/products");
      }
      setLoading(false);
    });
  };
  const updateProduct = (data) => {
    const updatedForm = handleData(data);
    useUPDATE("vendor/products/", {
      form: updatedForm,
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
    });
  };

  const deleteProduct = (id) => {
    setLoading(true);
    useDELETE("vendor/products", {
      data: { id: id },
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      if (res.type == "success") {
        setRefresh(!refresh);
      }
    });
  };
  return {
    products,
    deleteProduct,
    createProduct,
    updateProduct,
    form,
    setForm,
  };
};
