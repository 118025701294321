import { useRef, useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { themeColors } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";

const ThemeSettings = () => {
  const {
    setColor,
    setMode,
    setLang,
    currentMode,
    currentLang,
    currentColor,
    setThemeSettings,
  } = useStateContext();
  const settingsRef = useRef();

  useEffect(() => {
    const handleClose = (e) => {
      if (!settingsRef.current.contains(e.target)) {
        setThemeSettings(false);
      }
    };
    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, []);
  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        ref={settingsRef}
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
      >
        <div className="flex justify-between items-center p-4 ml-4">
          <p className="font-semibold text-lg">
            {translator.themeSettings.title[currentLang]}
          </p>
          <button
            type="button"
            onClick={() => setThemeSettings(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray dark:hover:bg-secondary-dark-bg"
          >
            <MdOutlineCancel />
          </button>
        </div>
        <div className="flex-col border-t-1 border-color p-4 ml-4">
          <p className="font-semibold text-xl ">
            {translator.themeSettings.Langs[currentLang]}
          </p>

          <div className="mt-4">
            <input
              type="radio"
              id="en"
              name="lang"
              value="ltr"
              className="cursor-pointer"
              onChange={(e) => setLang("en")}
              checked={currentLang == "en"}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="en" className="mx-2 text-md cursor-pointer">
              English
            </label>
          </div>
          <div className="mt-2">
            <input
              type="radio"
              id="ar"
              name="lang"
              value="rtl"
              onChange={(e) => setLang("ar")}
              className="cursor-pointer"
              checked={currentLang == "ar"}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="ar" className="mx-2 text-md cursor-pointer">
              اللغة العربية
            </label>
          </div>
        </div>
        <div className="flex-col border-t-1 border-color p-4 ml-4">
          <p className="font-semibold text-xl ">
            {translator.themeSettings.opetions.title[currentLang]}
          </p>

          <div className="mt-4">
            <input
              type="radio"
              id="light"
              name="theme"
              value="Light"
              className="cursor-pointer"
              onChange={setMode}
              checked={currentMode === "Light"}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="light" className="mx-2 text-md cursor-pointer">
              {translator.themeSettings.opetions.light[currentLang]}
            </label>
          </div>
          <div className="mt-2">
            <input
              type="radio"
              id="dark"
              name="theme"
              value="Dark"
              onChange={setMode}
              className="cursor-pointer"
              checked={currentMode === "Dark"}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="dark" className="mx-2 text-md cursor-pointer">
              {translator.themeSettings.opetions.dark[currentLang]}
            </label>
          </div>
        </div>
        <div className="p-4 border-t-1 border-color ml-4">
          <p className="font-semibold text-xl ">
            {translator.themeSettings.colors[currentLang]}
          </p>
          <div className="flex gap-3">
            {themeColors.map((item) => (
              <div
                className="relative mt-2 cursor-pointer flex gap-5 items-center"
                key={item.name}
              >
                <button
                  type="button"
                  className="h-10 w-10 flex items-center justify-center rounded-full cursor-pointer"
                  style={{ backgroundColor: item.color }}
                  onClick={() => setColor(item.color)}
                >
                  <BsCheck
                    className={` text-2xl font-bold text-white ${
                      item.color === currentColor ? "block" : "hidden"
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
