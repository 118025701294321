import { useEffect, useState } from "react";
import { SearchInput } from "./Input";
import { VisibleColumnButton } from "./Button";
import { DeleteIcon, EditIcon } from "./Icons";
import { useStateContext } from "../contexts/ContextProvider";
import PopUp from "./PopUp";

const UpDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2.4"
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
      />
    </svg>
  );
};

const Column = ({ children }) => {
  return (
    <td className="py-2">
      <p className="px-8 text-md whitespace-nowrap text-center font-medium text-gray-900 dark:text-gray-100">
        {children}
      </p>
    </td>
  );
};

const HeaderColumn = ({ children, onSort }) => {
  return (
    <th
      onClick={onSort ? () => onSort() : () => {}}
      className="px-4 border whitespace-nowrap border-t-0 border-b-0 border-l-0 border-r-gray-400 hover:bg-gray-200 text-center dark:hover:bg-gray-900 cursor-pointer py-2 bg-gray-100 dark:bg-gray-700 text-md font-bold text-gray-500 dark:text-gray-300 uppercase tracking-wider"
    >
      <div className="flex items-center justify-between ">
        <p className="mr-2">{children}</p>
        {onSort && <UpDownIcon />}
      </div>
    </th>
  );
};

const Row = ({ children }) => {
  return (
    <tr className="hover:bg-gray-50 dark:hover:bg-secondary-dark-bg dark:bg-secondary-dark-bg  cursor-pointer">
      {children}
    </tr>
  );
};

const Header = ({ children }) => {
  return (
    <thead className="h-12">
      <tr>{children}</tr>
    </thead>
  );
};

const Body = ({ children }) => {
  return (
    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
      {children}
    </tbody>
  );
};

const PaginationButton = ({ currentPage, setCurrentPage, children }) => {
  return (
    <button
      onClick={() => setCurrentPage()}
      className={`w-8 h-8 mt-6  border rounded-md 
              ${
                currentPage == children
                  ? "bg-blue-100 dark:bg-blue-50 font-bold border-blue-100 text-blue-500 dark:text-blue-600"
                  : "hover:bg-blue-100 dark:hover:bg-blue-50 font-bold hover:border-blue-100 dark:text-white hover:text-blue-500 dark:hover:text-blue-600"
              }
              
              `}
    >
      {children}
    </button>
  );
};

const Pagination = ({ setCurrentPage, currentPage, data, dataPerPage }) => {
  return (
    <div className="flex items-center justify-center gap-3">
      {/* data.length / dataPerPage */}
      {currentPage - 5 <= 0 ? (
        ""
      ) : (
        <PaginationButton
          currentPage={currentPage}
          setCurrentPage={() => setCurrentPage(currentPage - 5)}
        >
          {"<"}
        </PaginationButton>
      )}
      {currentPage - 1 === 0 ? (
        ""
      ) : (
        <PaginationButton
          currentPage={currentPage}
          setCurrentPage={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </PaginationButton>
      )}
      <PaginationButton
        currentPage={currentPage}
        setCurrentPage={() => setCurrentPage(currentPage)}
      >
        {currentPage}
      </PaginationButton>
      {data.length / dataPerPage >= currentPage && (
        <PaginationButton
          currentPage={currentPage}
          setCurrentPage={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </PaginationButton>
      )}
      {data.length / dataPerPage >= currentPage + 5 && (
        <PaginationButton
          currentPage={currentPage}
          setCurrentPage={() => setCurrentPage(currentPage + 5)}
        >
          {">"}
        </PaginationButton>
      )}
    </div>
  );
};

export default function Table({
  columns,
  data,
  children,
  options,
  Form = false,
  handleEdit,
  handleDelete,
}) {
  const { currentDir } = useStateContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [cols, setCols] = useState([]);
  const [sortedBy, setSortedBy] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const dataPerPage = 5;
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  useEffect(() => {
    setRows(data);
    setCols(Object.keys(columns));
  }, [data]);

  function orderBy(prop = "name") {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return -1;
      } else if (a[prop] > b[prop]) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  function reverseOrder(orderFn) {
    return function (a, b) {
      return orderFn(b, a);
    };
  }

  const handleOnSort = (col, rows) => {
    if (sortedBy[1] && sortedBy[0] == col) {
      setSortedBy([col, false]);
      setRows(rows.sort(orderBy(col)));
    } else {
      setSortedBy([col, true]);
      setRows(rows.sort(reverseOrder(orderBy(col))));
    }
  };

  const handleOnSelectColumn = (column) => {
    if (cols.includes(column)) {
      setCols(cols.filter((col) => col != column));
    } else {
      let s = [...cols, column];
      setCols(Object.keys(columns).filter((column) => s.includes(column)));
    }
  };

  const search = (val) => {
    if (val == "") {
      setRows(data);
    } else {
      setRows(
        rows.filter((row) => {
          let values = Object.values(row);
          let found = values.filter((value) => {
            return value
              ?.toString()
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase());
          });
          return found.length > 0;
        })
      );
    }
  };
  return (
    <div>
      <div className="flex relative items-center justify-between mb-4">
        <VisibleColumnButton
          cols={cols}
          columns={columns}
          onChange={(col) => handleOnSelectColumn(col)}
        />
        <SearchInput onChange={(val) => search(val)} />
      </div>
      <div className="overflow-x-auto h-[345px] bg-white dark:bg-secondary-dark-bg rounded-sm shadow-md">
        {rows.length <= 0 && children}

        {rows.length > 0 && (
          <table className="relative min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <Header>
              {cols.map((col) => (
                <HeaderColumn
                  key={col}
                  sortedBy={col === sortedBy}
                  onSort={() => handleOnSort(col, rows)}
                >
                  {columns[col].title}
                </HeaderColumn>
              ))}
              {options && (
                <HeaderColumn>
                  {currentDir == "rtl" ? "تحديث" : "actions"}
                </HeaderColumn>
              )}
            </Header>

            <Body>
              {rows.slice(indexOfFirstData, indexOfLastData).map((row) => (
                <Row key={row.id}>
                  {Object.keys(columns).map((key) => {
                    if (cols.includes(key))
                      return (
                        <Column key={`${key}_${row.id}`}>
                          {columns[key].render
                            ? columns[key].render(row)
                            : row[key]}
                        </Column>
                      );
                  })}
                  {options && (
                    <Column key={`_${row.id}`}>
                      <div className="flex justify-between w-full">
                        <EditIcon
                          onClick={
                            Form
                              ? () => setOpen(row.id)
                              : () => handleEdit(row.id)
                          }
                        />
                        <div className="px-2"></div>
                        {Form
                          ? open == row.id && (
                              <Form
                                action={"Update"}
                                data={row}
                                setOpen={() => setOpen(false)}
                                onSubmit={(data) => handleEdit(data)}
                              />
                            )
                          : ""}
                        <DeleteIcon onClick={() => setOpenPopUp(true)} />
                        {openPopUp && (
                          <PopUp onClose={() => setOpenPopUp(false)}>
                            <div>
                              <p className="text-xl">
                                {currentDir === "ltr"
                                  ? "Do you want to delete this item"
                                  : "هل تريد حذف هذا العنصر"}
                              </p>
                              <div
                                dir="ltr"
                                className="flex items-end justify-end"
                              >
                                <button className="px-4 py-2 font-semibold bg-red-500 hover:bg-red-600 text-white rounded-md mx-2 mt-6">
                                  {currentDir === "ltr" ? "Cancel" : "الغاء"}
                                </button>
                                <button
                                  onClick={() => {
                                    handleDelete(row.id);
                                    setOpenPopUp(false);
                                  }}
                                  className="px-4 py-2 font-semibold bg-green-500 hover:bg-green-600 text-white rounded-md mx-2 mt-6"
                                >
                                  {currentDir === "ltr" ? "Yes" : "نعم"}
                                </button>
                              </div>
                            </div>
                          </PopUp>
                        )}
                      </div>
                    </Column>
                  )}
                </Row>
              ))}
            </Body>
          </table>
        )}
      </div>
      <Pagination
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        data={data}
        dataPerPage={dataPerPage}
      />
    </div>
  );
}
