import { Header } from "../components";
import { DragAndDrop, Input } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useSettings } from "../api/auth";
import Avatar from "../data/avatar.jpeg";
import { useStateContext } from "../contexts/ContextProvider";
import { URL } from "../urls";
import { translator } from "../translation";

function Settings() {
  const { currentLang } = useStateContext();
  const { settings, setSettings, updaetSettings } = useSettings();

  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <Header
        category={translator.shared.paper[currentLang]}
        title={translator.forms.settings.title[currentLang]}
      />
      <div className="flex flex-col items-center justify-center mt-4 rounded-lg w-full">
        <DragAndDrop
          id="avatar"
          className="w-24 h-24 rounded-full mb-8"
          value={settings.image ? URL + settings.image : Avatar}
          onChange={(image) => setSettings({ ...settings, image: image })}
        />
        <div>
          <div className="mb-4 block md:flex justify-between">
            <Input
              onChange={(e) =>
                setSettings({ ...settings, firstName: e.target.value })
              }
              value={settings.firstName}
              label={translator.forms.settings.firstName[currentLang]}
              type="text"
            />
            <span className="px-4"></span>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lastName: e.target.value })
              }
              value={settings.lastName}
              label={translator.forms.settings.firstName[currentLang]}
              type="text"
            />
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) =>
                setSettings({ ...settings, email: e.target.value })
              }
              value={settings.email}
              label={translator.forms.settings.email[currentLang]}
              type="email"
            />
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) =>
                setSettings({ ...settings, phoneNumber: e.target.value })
              }
              value={settings.phoneNumber}
              type="text"
              label={translator.forms.settings.phoneNumber[currentLang]}
            />
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) =>
                setSettings({ ...settings, oldPassword: e.target.value })
              }
              value={settings.oldPassword}
              type="password"
              label={translator.forms.settings.oldPassword[currentLang]}
            />
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) =>
                setSettings({ ...settings, password: e.target.value })
              }
              value={settings.password}
              type="password"
              label={translator.forms.settings.password[currentLang]}
            />
          </div>
          <div className="mb-6">
            <Input
              value={settings.passwordConfermation}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  passwordConfermation: e.target.value,
                })
              }
              type="password"
              label={translator.forms.settings.confirmPassword[currentLang]}
            />
          </div>
        </div>
        <div className="flex w-full justify-center">
          <ActionButton onClick={() => updaetSettings()} className="px-8">
            {translator.forms.settings.action[currentLang]}
          </ActionButton>
        </div>
      </div>
    </div>
  );
}

export default Settings;
