import { Header, PopUp, ToolTip } from "../components";
import { ActionButton } from "../components/Button";
import { Input, SelectInput, DragAndDrop } from "../components/Input";
import ReactMarkdown from "react-markdown";
import { Eye, EyeSlash, MinusIcon, PlusIcon } from "../components/Icons";
import { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useProducts } from "../api/product";
import { useGetCategories } from "../api/category";
import { useStateContext } from "../contexts/ContextProvider";
import { MEDIA_URL } from "../urls";
import { translator } from "../translation";

const PackageForm = ({ setOpen, setForm }) => {
  const [pack, setPack] = useState({ image: "", title: "", quantity: "" });
  const { currentLang, handleNotification } = useStateContext();
  const handleAdd = () => {
    if (pack.title && pack.image && pack.quantity) {
      setForm((form) => ({ ...form, packs: [...form.packs, pack] }));
      setOpen(false);
    } else {
      handleNotification({
        type: "error",
        message: translator.forms.product.error[currentLang],
      });
    }
  };
  return (
    <PopUp onClose={() => setOpen(false)}>
      <div className="flex px-4">
        <ToolTip title="Add package Image">
          <DragAndDrop
            id="pack-upload"
            onChange={({ file, data }) =>
              setPack({ ...pack, image: file, data: data })
            }
            label={<PlusIcon />}
            className="w-24 h-24 mx-8 rounded-lg"
          />
        </ToolTip>
        <div>
          <Input
            value={pack.title}
            onChange={(e) => setPack({ ...pack, title: e.target.value })}
            label={translator.forms.product.pack.name[currentLang]}
            type="text"
          />
          <div className="mt-2"></div>
          <Input
            value={pack.quantity}
            onChange={(e) =>
              setPack({ ...pack, quantity: parseInt(e.target.value) })
            }
            label={translator.forms.product.pack.quantity[currentLang]}
            type="number"
          />
        </div>
      </div>
      <ActionButton
        type="button"
        onClick={() => handleAdd()}
        className="float-right mt-6 w-36"
      >
        {translator.forms.product.pack.action[currentLang]}
      </ActionButton>
    </PopUp>
  );
};

export const LinkButton = ({ children }) => {
  return (
    <Link
      to="/products/"
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
    </Link>
  );
};

function CreateProduct() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { createProduct, form, setForm, updateProduct } = useProducts(id);
  const { handleNotification, currentLang } = useStateContext();
  const { categories } = useGetCategories();
  const [preview, setPerview] = useState(false);

  const handleSubmit = () => {
    if (
      !form.images ||
      !form.title ||
      !form.category ||
      !form.price ||
      !form.quantity ||
      !form.description
    ) {
      handleNotification({ type: "error", message: "complete missing data" });
      return;
    }
    if (!id) createProduct(form);
    if (id) {
      updateProduct(form);
    }
  };

  const totalQuantity = useMemo(() => {
    return form.packs?.reduce((prev, value) => prev + value.quantity, 0);
  }, [form.packs]);

  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.forms.product.title[currentLang]}
        />
        <LinkButton>{translator.forms.product.back[currentLang]}</LinkButton>
      </header>
      <form className="flex flex-wrap gap-4 p-4 items-center justify-center">
        <div className="pt-6 flex">
          <div className="flex flex-col gap-2">
            {form.images?.map((image) => (
              <div
                style={{
                  backgroundSize: "cover",
                  backgroundImage: `url(${
                    image.data ? image.data : `data:image/jpeg;base64,${image.image}`
                  })`,
                }}
                className="w-16 h-16 mr-2 border border-gray-300 rounded-md"
              >
                <MinusIcon
                  onClick={() =>
                    setForm({
                      ...form,
                      images: form.images.filter((i) => i != image),
                    })
                  }
                />
              </div>
            ))}
          </div>
          <DragAndDrop
            id="product-image"
            onChange={(file) => {
              if (form.images.length < 4)
                setForm({ ...form, images: [...form.images, file] });
              else
                handleNotification({
                  type: "error",
                  message: translator.forms.product.imageError[currentLang],
                });
            }}
            label={translator.forms.product.image[currentLang]}
            className="h-[310px] rounded-lg ml-2 w-56 max-sm:w-[300px]"
          />
        </div>
        <div>
          <Input
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            label={translator.forms.product.name[currentLang]}
            type="text"
            className="w-[500px] mb-5"
          />
          <div className="flex mt-4 justify-between mb-5">
            <SelectInput
              values={categories}
              value={form.category}
              item="name"
              onChange={(e) => setForm({ ...form, category: e.target.value })}
              className="w-[300px]"
              label={translator.forms.product.category[currentLang]}
            />
            <Input
              value={totalQuantity > 0 ? totalQuantity : form.quantity}
              onChange={(e) => setForm({ ...form, quantity: e.target.value })}
              label={translator.forms.product.quantity[currentLang]}
              type="number"
              className="w-[120px]"
            />
          </div>
          <div className="flex mt-4 justify-between mb-5">
            <div className="mx-4">
              <Input
                value={form.price}
                onChange={(e) => setForm({ ...form, price: e.target.value })}
                label={translator.forms.product.sellPrice[currentLang]}
                type="number"
                className="w-[140px]"
              />
            </div>
            <Input
              value={form.buy_price}
              onChange={(e) => setForm({ ...form, buy_price: e.target.value })}
              label={translator.forms.product.buyPrice[currentLang]}
              type="number"
              className="w-[140px]"
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label
              className="block dark:text-white text-md text-gray-700 font-bold mb-2"
              htmlFor="editor"
            >
              {translator.forms.product.description[currentLang]}
            </label>
            <div
              onClick={() => setPerview(!preview)}
              className="p-2 border rounded-md cursor-pointer bg-gray-50 mb-2"
            >
              {!preview ? <Eye /> : <EyeSlash />}
            </div>
          </div>
          {!preview && (
            <textarea
              className="overflow-y-auto focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 appearance-none dark:text-white bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-tight min-w-[355px] max-w-[355px] min-h-[250px] max-h-[250px]"
              value={form.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
          )}
          {preview && (
            <ReactMarkdown
              className="overflow-y-auto focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 appearance-none dark:text-white bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-tight min-w-[355px] max-w-[355px] min-h-[250px] max-h-[250px]"
              children={form.description}
            />
          )}
        </div>
        <div className="flex w-full mt-4">
          <div>
            <label
              className="block dark:text-white text-md text-gray-700 font-bold mb-6"
              htmlFor="editor"
            >
              {translator.forms.product.pack.title[currentLang]}
            </label>
            <div className="flex">
              <ToolTip title="New package">
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="w-16 h-16 mr-2 border border-gray-300 rounded-md flex items-center justify-center"
                >
                  <PlusIcon />
                </button>
              </ToolTip>

              <div className="flex flex-wrap gap-y-4">
                {form.packs?.map((pack) => (
                  <div className="block">
                    <div
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${
                          pack.data ? pack.data : `data:image/jpeg;base64,${pack.image}`
                        })`,
                      }}
                      className="w-16 h-16 mr-2 border border-gray-300 rounded-md"
                    >
                      <MinusIcon
                        onClick={() =>
                          setForm({
                            ...form,
                            packs: form.packs.filter((p) => p != pack),
                          })
                        }
                      />
                    </div>
                    <p className="text-center font-bold">{pack.title}</p>
                  </div>
                ))}
              </div>
              {open && <PackageForm setOpen={setOpen} setForm={setForm} />}
            </div>
          </div>
        </div>
        <ActionButton
          onClick={() => handleSubmit()}
          className="self-end mt-6 w-64"
        >
          {translator.forms.product.title[currentLang]}
        </ActionButton>
      </form>
    </div>
  );
}

export default CreateProduct;
