import { useEffect } from "react";
import { useGET } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useInitTracker = () => {
  const { setTrackID, trackerID } = useStateContext();

  useEffect(() => {
    if (localStorage?.getItem("admin-trackID")) {
      setTrackID(localStorage?.getItem("admin-trackID"));
    }
    if (!localStorage?.getItem("admin-trackID")) {
      useGET("tracking/init/").then((res) => {
        if (res.type == "success") {
          setTrackID(res.data);
          localStorage.setItem("admin-trackID", res.data);
        }
      });
    }
  }, []);
};
