import { useState, useEffect } from "react";
import { useGET } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const { handleNotification, user, trackerID } = useStateContext();

  useEffect(() => {
    useGET("vendor/customers", { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res.type == "success") setCustomers(res.data);
        if (res.type == "error") handleNotification(res);
      }
    );
  }, []);

  return { customers };
};
