const ToolTip = ({ children, title }) => {
  return (
    <div className="relative group inline-block">
      {children}
      <div className="absolute bg-gray-800 dark:bg-slate-200 text-white dark:text-gray-900 text-sm px-2 py-1 rounded-md opacity-0 group-hover:opacity-90 transition-opacity duration-200 ease-in-out">
        {title}
      </div>
    </div>
  );
};

export default ToolTip;
