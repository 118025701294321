import { useState } from "react";
import { useLogin } from "../api/auth";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { Logo } from "../components/Sidebar";
import { NotificationIcon } from "../components/Icons";

export default function Login() {
  const { user } = useStateContext();
  const navigate = useNavigate();
  if (user.access) navigate("/");
  const [auth, setAuth] = useState({ username: "", password: "" });
  const { handleLogin } = useLogin();
  return (
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <div className="absolute top-4 left-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-9 h-9 mr-4 p-0.5 rounded-md  bg-gradient-to-r from-[#ff80b5] to-[#9089fc] text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
          />
        </svg>
      </div>
      <div className="w-full h-[100vh] flex items-center justify-center">
        <form className="flex flex-col gap-4 p-8">
          <h1 className="font-bold text-4xl">Login</h1>
          <p className="mb-8">To continue to Comercify dashboard</p>
          <label htmlFor="username">Email</label>
          <input
            onChange={(e) => setAuth({ ...auth, username: e.target.value })}
            type="text"
            id="username"
            className="w-96 py-2 px-2 border border-gray-200 rounded-md"
          />
          <label htmlFor="pass">Password</label>
          <input
            onChange={(e) => setAuth({ ...auth, password: e.target.value })}
            type="password"
            id="pass"
            className="w-96 py-2 px-2 border border-gray-200 rounded-md"
          />
          <button
            type="button"
            onClick={() => handleLogin(auth)}
            className="px-6 py-2 mt-8 bg-blue-600 text-white"
          >
            login
          </button>
        </form>
      </div>
      <div
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgb(18, 38, 71) 0%, rgb(9, 14, 35) 100%)",
        }}
        className="w-full h-[100vh] flex flex-col items-center justify-center"
      >
        <p className="text-white font-bold text-3xl">
          Welcome to{" "}
          <span
            style={{
              background: "-webkit-linear-gradient(45deg,  #f72585, #7209b7)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Comercify
          </span>
        </p>
        <div className="mt-6">
          <div className="rounded-md bg-white px-4 py-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <NotificationIcon /> <p>New Order</p>
              </div>
              <p className="text-green-500 font-bold ml-4">+300$</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
