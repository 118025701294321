export const translator = {
  groupLinks: [
    {
      id: 1,
      name: {
        en: "DASHBOARD",
        ar: "لوحة التحكم",
      },
      links: [
        {
          id: 1,
          name: {
            en: "Dashboard",
            ar: "لوحة التحكم",
          },
        },
      ],
    },
    {
      id: 2,
      name: {
        en: "MANAGMENT",
        ar: "التسيير",
      },
      links: [
        {
          id: 1,
          name: {
            en: "Orders",
            ar: "الطلبيات",
          },
        },
        {
          id: 2,
          name: {
            en: "Products",
            ar: "المنتوجات",
          },
        },
        {
          id: 3,
          name: {
            en: "Customers",
            ar: "الزبائن",
          },
        },
        {
          id: 4,
          name: {
            en: "Discounts",
            ar: "التخفيضات",
          },
        },
        {
          id: 5,
          name: {
            en: "Coupons",
            ar: "الكوبونات",
          },
        },
        {
          id: 6,
          name: {
            en: "Settings",
            ar: "الاعدادات",
          },
        },
        {
          id: 7,
          name: {
            en: "Visitors",
            ar: "الزوار",
          },
        },
        {
          id: 8,
          name: {
            en: "Logout",
            ar: "تسجيل الخروج",
          },
        },
      ],
    },
  ],
  themeSettings: {
    title: {
      en: "Settings",
      ar: "الاعدادت",
    },
    Langs: {
      en: "Language",
      ar: "اللغة",
    },
    opetions: {
      title: {
        en: "Theme Option",
        ar: "خيارات النمط",
      },
      dark: {
        en: "Dark mode",
        ar: "الوضع الداكن",
      },
      light: {
        en: "Light mode",
        ar: "الوضع الفاتح",
      },
    },
    colors: {
      en: "Theme Colors",
      ar: "نمط الالوان",
    },
  },
  dashboard: {
    cards: [
      {
        en: "Orders",
        ar: "عدد الطلبيات",
      },
      {
        en: "Products",
        ar: "عدد المنتوجات",
      },
      {
        en: "Sales",
        ar: "مجموع المبيعات",
      },
      {
        en: "Ratings",
        ar: "عدد التقييمات",
      },
    ],
  },
  shared: {
    paper: {
      en: "page",
      ar: "صفحة",
    },
    cols: {
      en: "Columns",
      ar: "الاعمدة",
    },
    search: {
      en: "Search ...",
      ar: "بحث ...",
    },
    add: {
      en: "Create",
      ar: "اضافة",
    },
  },
  tables: {
    orders: {
      products: {
        en: "products",
        ar: "ألمنتوجات",
      },
      prices: {
        en: "prices",
        ar: "الاسعار",
      },
      packs: {
        en: "Packs",
        ar: "الحزم",
      },
      coupons: {
        en: "Coupons",
        ar: "الكوبونات",
      },
      customers: {
        en: "Customers",
        ar: "الزبائن",
      },
      addresses: {
        en: "Address",
        ar: "العنوان",
      },
      phoneNumbers: {
        en: "phone number",
        ar: "رقم الهاتف",
      },

      postalCodes: {
        en: "postal code",
        ar: "الرمز البريدي",
      },
      status: {
        en: "status",
        ar: "الحالة",
      },
      date: {
        en: "Date",
        ar: "التاريخ",
      },
      null: {
        en: "No orders for now",
        ar: "لا يوجد طلبيات حتى الان",
      },
    },
    products: {
      products: {
        en: "Product",
        ar: "المنتوجات",
      },
      buyPrice: {
        en: "Buy Price",
        ar: "سعر الشراء",
      },
      price: {
        en: "Sell Price",
        ar: "سعر البيع",
      },
      actPrice: {
        en: "Price after discount",
        ar: "السعر بعد التخفيض",
      },
      inStock: {
        en: "quantity",
        ar: "الكمية",
      },
      packs: {
        en: "Packages",
        ar: "الحزم",
      },
      orders: {
        en: "orders",
        ar: "الطلبيات",
      },
      earning: {
        en: "earning",
        ar: "المدخول",
      },
      reviews: {
        en: "Total reviews",
        ar: "عدد التقييمات",
      },
      avgReviews: {
        en: "reviews avg",
        ar: "متوسط التقييمات",
      },
      null: {
        en: "Create your first product",
        ar: "انشئ اول منتج لك",
      },
    },
    discounts: {
      title: {
        en: "title",
        ar: "الاسم",
      },
      percentage: {
        en: "percentage",
        ar: "النسبة",
      },
      endDate: {
        en: "end date",
        ar: "تاريخ النهاية",
      },
      product: {
        en: "products",
        ar: "المنتوجات",
      },
      orders: {
        en: "orders",
        ar: "الطلبيات",
      },
      null: {
        en: "Add discounts to your prodcuts",
        ar: "اضف تخفيضات لمنتجاتك",
      },
    },
    coupons: {
      code: {
        en: "code",
        ar: "الكود",
      },
      value: {
        en: "value",
        ar: "القبمة",
      },
      endDate: {
        en: "end date",
        ar: "تاريخ النهاية",
      },
      product: {
        en: "product",
        ar: "المنتوجات",
      },
      orders: {
        en: "orders",
        ar: "الطلبيات",
      },
      null: {
        en: "Add coupons to your prodcuts",
        ar: "اضف كوبونات لمنتجاتك",
      },
    },
    customers: {
      firstName: {
        en: "Customers",
        ar: "الزبائن",
      },
      orders: {
        en: "orders",
        ar: "الطلبيات",
      },
      spent: {
        en: "spent",
        ar: "المصروفات",
      },
      reviews: {
        en: "reviews",
        ar: "التقييمات",
      },
      null: {
        en: "No customers for now",
        ar: "لا يوجد زبائن حتى الان",
      },
    },
  },
  forms: {
    shared: {
      product: {
        en: "Prodcut",
        ar: "المنتوج",
      },
    },
    coupons: {
      title: {
        en: "Coupon",
        ar: "كوبون",
      },
      coupon: {
        en: "Coupon",
        ar: "كوبون",
      },
      value: {
        ar: "القيمة",
        en: "Value",
      },
      endDate: {
        ar: "تاريخ النهاية",
        en: "End Date",
      },
    },
    product: {
      title: {
        ar: "اضافة منتوج",
        en: "Create Product",
      },
      back: {
        ar: "رجوع",
        en: "Back",
      },
      pack: {
        title: {
          ar: "اضافة حزم",
          en: "Create Packages :",
        },
        name: {
          ar: "اسم الحزمة",
          en: "Package Name",
        },
        quantity: {
          ar: "الكمية",
          en: "Quantity",
        },
        action: {
          ar: "اضافة الحزمة",
          en: "Add Package",
        },
      },
      image: {
        en: "Click or drag image here",
        ar: "تحميل صورة",
      },
      name: {
        en: "Title",
        ar: "اسم النتوج",
      },
      category: {
        ar: "الفئة",
        en: "Category",
      },
      quantity: {
        ar: "الكمية",
        en: "Quantity",
      },
      sellPrice: {
        ar: "سعر البيع",
        en: "Sell Price",
      },
      buyPrice: {
        ar: "سعر الشراء",
        en: "Buy Price",
      },
      description: {
        ar: "الوصف",
        en: "Description",
      },
      action: {
        ar: "حفظ المنتوج",
        en: "Save Product",
      },
      error: {
        en: "complete missing informations",
        ar: "الرجاء اكمال البيانات الناقصة",
      },
      imageError: {
        ar: "الحد الاقصى 4 صور",
        en: "Can't add more images",
      },
    },
    discounts: {
      title: {
        en: "Discount",
        ar: "تخفيض",
      },
      name: {
        en: "Title",
        ar: "الاسم",
      },
      percentage: {
        en: "percentage %",
        ar: "% النسبة",
      },
      endDate: {
        ar: "تاريخ النهاية",
        en: "End Date",
      },
    },
    settings: {
      title: {
        en: "Settings",
        ar: "الاعدادت",
      },
      firstName: {
        ar: "الاسم الاول",
        en: "First Name",
      },
      lastName: {
        ar: "الاسم الاخير",
        en: "Last Name",
      },
      email: {
        ar: "البريد الالكتروني",
        en: "Email",
      },
      phoneNumber: {
        ar: "رقم الهاتف",
        en: "Phone Number",
      },
      oldPassword: {
        en: "Old Password",
        ar: "كلمة السر السابقة",
      },
      password: {
        en: "Password",
        ar: "كلمة السر الجديدة",
      },
      confirmPassword: {
        ar: "تاكيد كلمة السر",
        en: "Confirm Password",
      },
      action: {
        ar: "تحديث",
        en: "Update",
      },
    },
  },
  pages: {
    orders: {
      name: {
        en: "Orders",
        ar: "الطلبيات",
      },
      choices: [
        {
          en: "Submitted",
          ar: "تم الطلب",
        },
        {
          en: "Shipped",
          ar: "تم الشحن",
        },
        {
          en: "Delevred",
          ar: "تم التسليم",
        },
        {
          en: "Refound",
          ar: "تم الاسترجاع",
        },
      ],
    },
    products: {
      name: {
        en: "Products",
        ar: "المنتوجات",
      },
      linkBtn: {
        en: "Product",
        ar: "منتوج",
      },
    },
    discounts: {
      name: {
        en: "Discounts",
        ar: "التخفيضات",
      },
      linkBtn: {
        en: "Discount",
        ar: "تخفيض",
      },
    },
    coupons: {
      name: {
        en: "Coupons",
        ar: "الكوبونات",
      },
      linkBtn: {
        en: "Coupon",
        ar: "كوبون",
      },
    },
    customers: {
      name: {
        en: "Customers",
        ar: "الزبائن",
      },
    },
    visitors: {
      name: {
        en: "Visitors",
        ar: "الزوار",
      },
    },
  },
  charts: {
    totalPrice: {
      title: {
        en: "Total Prices Chart",
        ar: "منحنى مجموع المبيعات",
      },
      choices: [
        {
          ar: "مجموع المبيعات بالنسبة للاشهر",
          en: "Total Prices By Month",
        },
        {
          ar: "مجموع المبيعات بالنسبة للسنوات",
          en: "Total Prices By Year",
        },
        {
          ar: "مجموع المبيعات بالنسبة للعملاء",
          en: "Total Prices By User",
        },
        {
          ar: "مجموع المبيعات بالنسبة للولايات",
          en: "Total Prices By Province",
        },
      ],
    },
  },
};
