export const formattedDate = (v) => {
  const date = new Date(v);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const renderDate = (v) => {
  const date = new Date(v);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    day: "numeric",
    month: "long",
  });
  return formattedDate;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonth = (dateString) => {
  const date = new Date(dateString);

  const monthValue = date.getMonth();

  return monthNames[monthValue];
};

export const handleOrdersChartData = (dataset, label) => {
  const dataByColumn = dataset.reduce((acc, item) => {
    const date = new Date(item.created_at);
    var column;
    if (label === "month") {
      column = date.toLocaleString("default", { month: "short" });
    }
    if (label === "year") {
      column = date.toLocaleString("default", { year: "numeric" });
    }
    if (label === "user") {
      column = item.user__first_name + " " + item.user__last_name;
    }
    if (label === "province") {
      column = item.shipping__wilaya;
    }
    if (!acc[column]) {
      acc[column] = { total: item.price };
    } else {
      acc[column].total += item.price;
    }

    return acc;
  }, {});

  const totalPrices = Object.keys(dataByColumn).map((column) => ({
    column: column,
    totalPrice: dataByColumn[column].total,
  }));

  const totalPricesData = totalPrices.map((item) => item.totalPrice);
  const labels = totalPrices.map((item) => item.column);

  return { totalPricesData, labels };
};
