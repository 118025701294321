import { useEffect, useState } from "react";
import { useGET } from "./utils";
import { useStateContext } from "../contexts/ContextProvider";

export const useGetCategories = () => {
  const [categories, setCategories] = useState([]);
  const { handleNotification, trackerID, user } = useStateContext();
  useEffect(() => {
    useGET(`categories/`, { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res?.type == "error") handleNotification(res);
        if (res?.type == "success") setCategories(res?.data);
      }
    );
  }, []);
  return { categories };
};
