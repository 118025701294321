import { Header, Table } from "../components";
import { useOrders } from "../api/order";
import { renderDate } from "../utils";
import Avatar from "../data/avatar.jpeg";
import { SelectInput } from "../components/Input";
import { useStateContext } from "../contexts/ContextProvider";
import { MEDIA_URL } from "../urls";
import { translator } from "../translation";

function Orders() {
  const { updateOrder, orders } = useOrders();
  const { currentLang } = useStateContext();
  const handleStatus = (status) => {
    const choice = CHOICES.filter((choice) => choice.value == status);
    return choice[0].id;
  };

  const handleUpdate = (status, id) => {
    updateOrder(status, id);
  };

  const columns = {
    product__title: {
      title: translator.tables.orders.products[currentLang],
      render: (row) => (
        <div className="flex items-center justify-start px-6">
          <img
            className="w-10 h-10 rounded-full mx-2"
            src={MEDIA_URL + row.product_image}
            alt=""
          />
          {row.product__title}
        </div>
      ),
    },
    price: {
      title: translator.tables.orders.prices[currentLang],
      render: (row) => row.price.toFixed(2),
    },
    pack__title: {
      title: translator.tables.orders.packs[currentLang],
    },
    coupon__code: {
      title: translator.tables.orders.coupons[currentLang],
      render: (row) =>
        row.coupon__code ? (
          row.coupon__code
        ) : (
          <p className="text-lg font-bold">---</p>
        ),
    },
    user__first_name: {
      title: translator.tables.orders.customers[currentLang],
      render: (row) => (
        <div className="flex items-center px-6 w-full justify-center">
          <img
            className="w-10 h-10 rounded-full mx-2"
            src={row.user__image ? MEDIA_URL + row.user__image : Avatar}
            alt=""
          />
          {row.user__first_name + " " + row.user__last_name}
        </div>
      ),
    },
    shipping_info__address: {
      title: translator.tables.orders.addresses[currentLang],
    },
    shipping_info__phone_number: {
      title: translator.tables.orders.phoneNumbers[currentLang],
    },
    shipping_info__postal_code: {
      title: translator.tables.orders.postalCodes[currentLang],
    },
    status: {
      title: translator.tables.orders.status[currentLang],
      render: (row) => {
        return (
          <SelectInput
            multiLang
            value={handleStatus(row.status)}
            item={"title"}
            values={CHOICES}
            onChange={(e) => handleUpdate(e.target.value, row.id)}
          />
        );
      },
    },
    created_at: {
      title: translator.tables.orders.date[currentLang],
      render: (row) => renderDate(row.created_at),
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <Header
        category={translator.shared.paper[currentLang]}
        title={translator.pages.orders.name[currentLang]}
      />
      <Table columns={columns} data={orders}>
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.orders.null[currentLang]}
          </p>
        </div>
      </Table>
    </div>
  );
}
export default Orders;

const CHOICES = [
  {
    id: 0,
    value: "SUBMITTED",
    title: translator.pages.orders.choices[0],
  },
  {
    id: 1,
    value: "SHIPPED",
    title: translator.pages.orders.choices[1],
  },
  {
    id: 2,
    value: "DELEVRED",
    title: translator.pages.orders.choices[2],
  },
  {
    id: 3,
    value: "REFOUND",
    title: translator.pages.orders.choices[3],
  },
];
