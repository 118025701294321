import { useState } from "react";
import { Header, Table, PopUp } from "../components";
import { PlusIcon } from "../components/Icons";
import { Input, SelectInput } from "../components/Input";
import { ActionButton } from "../components/Button";
import { useCoupons } from "../api/coupon";
import { useProducts } from "../api/product";
import { formattedDate, renderDate } from "../utils";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";
import { MEDIA_URL } from "../urls";
import { translator } from "../translation";

export const LinkButton = ({ children, onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className="flex border border-blue-400 items-center py-2 px-4 hover:bg-sky-100 dark:hover:bg-gray-700 rounded-md cursor-pointer"
    >
      <PlusIcon />
      <span className="font-medium ml-1 mr-2 dark:text-white">{children}</span>
    </button>
  );
};

const CouponForm = ({ setOpen, onSubmit, data, action }) => {
  const { products } = useProducts();
  const { handleNotification } = useStateContext();
  const { currentLang } = useStateContext();
  const [coupon, setCoupon] = useState({
    id: data.id,
    product_id: data.product__id,
    code: data.code,
    value: data.value,
    end_date: formattedDate(data.end_date),
  });

  const handleSubmit = () => {
    if (
      !coupon.product_id ||
      !coupon.code ||
      !coupon.value ||
      !coupon.end_date
    ) {
      handleNotification({ type: "error", message: "Complete missing data " });
      return;
    }
    onSubmit(coupon);
    setOpen();
  };
  return (
    <PopUp
      title={`${action} ${translator.forms.coupons.title[currentLang]}`}
      onClose={() => setOpen()}
    >
      <div className="px-6">
        <SelectInput
          onChange={(e) => setCoupon({ ...coupon, product_id: e.target.value })}
          values={products}
          value={coupon.product_id}
          item={"title"}
          label={translator.forms.coupons.title[currentLang]}
          className="w-[250px] mb-4 mt-4"
        />
        <Input
          value={coupon.code}
          onChange={(e) => setCoupon({ ...coupon, code: e.target.value })}
          label={
            translator.forms.coupons.title[currentLang] == "rtl"
              ? "الكود"
              : "Code"
          }
          type="text"
          className="w-[250px] mb-4"
        />
        <div className="flex justify-between">
          <Input
            value={coupon.value}
            onChange={(e) => setCoupon({ ...coupon, value: e.target.value })}
            label={translator.forms.coupons.title[currentLang] == "rtl"}
            type="number"
            className="max-w-[90px] mb-4"
          />
          <Input
            value={coupon.end_date}
            onChange={(e) => setCoupon({ ...coupon, end_date: e.target.value })}
            label={translator.forms.coupons.title[currentLang] == "rtl"}
            type="date"
            className="max-w-[200px] mb-4"
          />
        </div>
      </div>
      <div className="w-full">
        <ActionButton
          onClick={() => handleSubmit()}
          className="float-right mt-4 mr-4 px-8"
        >
          {action}
        </ActionButton>
      </div>
    </PopUp>
  );
};

function Coupons() {
  const [open, setOpen] = useState(false);
  const { currentLang } = useStateContext();
  const { coupons, createCoupon, updateCoupon, deleteCoupon } = useCoupons();
  const columns = {
    code: {
      title: translator.tables.coupons.code[currentLang],
    },
    value: {
      title: translator.tables.coupons.value[currentLang],
      render: (row) => <p>{row.value.toFixed(2)}</p>,
    },
    end_date: {
      title: translator.tables.coupons.endDate[currentLang],
      render: (row) => renderDate(row.end_date),
    },
    product__id: {
      title: translator.tables.coupons.product[currentLang],
      render: (row) => (
        <Link
          title="preview"
          className="flex items-center justify-center px-2 hover:text-blue-600 hover:underline"
        >
          <img
            className="rounded-full w-10 h-10 mx-2 "
            src={MEDIA_URL + row.product_image}
          />

          {row.product__title}
        </Link>
      ),
    },
    orders: {
      title: translator.tables.coupons.orders[currentLang],
    },
  };
  return (
    <main className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl max-sm:rounded-lg">
      <header className="flex justify-between items-center">
        <Header
          category={translator.shared.paper[currentLang]}
          title={translator.pages.discounts.name[currentLang]}
        />
        <LinkButton onClick={() => setOpen(true)} to="create/">
          {translator.pages.coupons.linkBtn[currentLang]}
        </LinkButton>
      </header>
      <Table
        columns={columns}
        data={coupons}
        handleEdit={updateCoupon}
        handleDelete={deleteCoupon}
        options
        Form={CouponForm}
      >
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.coupons.null[currentLang]}
          </p>
        </div>
      </Table>
      {open && (
        <CouponForm
          action={translator.shared.add[currentLang]}
          data={{
            code: "",
            value: "",
            end_date: "",
            product_id: "",
          }}
          setOpen={() => setOpen(false)}
          onSubmit={(data) => createCoupon(data)}
        />
      )}
    </main>
  );
}
export default Coupons;
