import { useState, useRef, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { translator } from "../translation";

const Button = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      type="button"
      onClick={() => setIsClicked(initialState)}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor} dark:hover:bg-secondary-dark-bg`}
    >
      {icon} {text}
    </button>
  );
};

export const VisibleColumnButton = ({ cols, columns, onChange }) => {
  const [toggle, setToggle] = useState(false);
  const compRef = useRef();

  useEffect(() => {
    const handleClose = (e) => {
      if (!compRef.current.contains(e.target)) {
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, []);

  const { currentLang, currentColor } = useStateContext();

  return (
    <div ref={compRef}>
      <button
        style={{ backgroundColor: currentColor }}
        onClick={() => setToggle(!toggle)}
        className={`px-6 py-[8px] rounded-md text-white font-bold`}
      >
        {translator.shared.cols[currentLang]}
      </button>
      <div
        className={`${
          !toggle && "hidden"
        } top-10 px-7 z-10 border border-gray-200 text-gray-900 dark:bg-gray-600 bg-white absolute dark:text-white font-bold p-4 shadow-lg rounded-md`}
      >
        <ul>
          {Object.keys(columns).map((column) => (
            <li
              key={column}
              onClick={() => onChange(column)}
              className="flex items-center justify-start mb-2 cursor-pointer"
            >
              <input
                type="checkbox"
                onChange={() => {}}
                checked={cols.includes(column)}
                className="mx-2"
              />
              <p className="uppercase">{columns[column].title}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const ActionButton = ({ className, onClick, children }) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`py-[8px] ${className} rounded-md bg-blue-500 text-white font-bold`}
    >
      {children}
    </button>
  );
};

export default Button;
