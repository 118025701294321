import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import avatar from "../data/avatar.jpeg";
import { ToolTip, Chat, Notification } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { URL } from "../urls";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => {
  return (
    <ToolTip title={title}>
      <button
        type="button"
        onClick={() => customFunc()}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-gray-200 dark:hover:bg-secondary-dark-bg"
      >
        <span
          style={{ background: dotColor }}
          className="absolute animate-bounce inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </ToolTip>
  );
};

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    currentDir,
    user,
    setScreenSize,
    screenSize,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <NavButton
        title="Menu"
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        <ToolTip title="Profile">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray dark:hover:bg-secondary-dark-bg rounded-lg"
            onClick={() => handleClick("userProfile")}
          >
            <img
              className="rounded-full w-8 h-8"
              src={user.image ? URL + user.image : avatar}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">
                {currentDir == "rtl" ? "مرحبا" : "Hi"},
              </span>{" "}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {user.name}
              </span>
            </p>
          </div>
        </ToolTip>
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />}
      </div>
    </div>
  );
};

export default Navbar;
