import React from "react";
import { Header, Table } from "../components";
import { useCustomers } from "../api/customers";
import Avatar from "../data/avatar.jpeg";
import { useStateContext } from "../contexts/ContextProvider";
import { MEDIA_URL } from "../urls";
import { translator } from "../translation";

const Customers = () => {
  const { customers } = useCustomers();
  const { currentLang } = useStateContext();
  const columns = {
    first_name: {
      title: translator.tables.customers.firstName[currentLang],
      render: (row) => (
        <div className="flex items-center px-6 w-full justify-center">
          <img
            className="w-8 h-8 rounded-full mx-2"
            src={row.image ? MEDIA_URL + row.image : Avatar}
            alt=""
          />
          {row.first_name + " " + row.last_name}
        </div>
      ),
    },
    orders: {
      title: translator.tables.customers.orders[currentLang],
    },
    spent: {
      title: translator.tables.customers.spent[currentLang],
      render: (row) => row.spent?.toFixed(2),
    },
    reviews: {
      title: translator.tables.customers.reviews[currentLang],
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <Header
        category={translator.shared.paper[currentLang]}
        title={translator.pages.customers.name[currentLang]}
      />
      <Table columns={columns} data={customers}>
        <div className="w-full bg-gray-50 text-gray-600 flex items-center justify-around h-[345px]">
          <p className="font-bold text-gray-600">
            {translator.tables.customers.null[currentLang]}
          </p>
        </div>
      </Table>
    </div>
  );
};

export default Customers;
