import { XIcon } from "./Icons";

const PopUp = ({ children, onClose, title }) => {
  return (
    <div className="fixed bg-half-transparent top-0 w-full h-full right-0 z-50 flex items-center justify-center">
      <div className="bg-white flex flex-col dark:bg-secondary-dark-bg py-4 px-4 rounded-md items-end">
        <div className="w-full mb-4 flex justify-between">
          <p className="text-xl pt-1 font-bold dark:text-white">{title}</p>
          <button
            onClick={() => onClose()}
            className="float-right p-2 text-red-600 bg-red-100 rounded-full"
          >
            <XIcon />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PopUp;
