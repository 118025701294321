import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useDELETE, useGET, usePOST, useUPDATE } from "./utils";

export const useDiscounts = () => {
  const { handleNotification, user, trackerID } = useStateContext();
  const [discounts, setDiscounts] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    useGET("vendor/discounts/", { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res.type == "success") setDiscounts(res.data);
        if (res.type == "error") handleNotification(res);
      }
    );
  }, [refresh]);

  const createDiscount = (data) => {
    usePOST("vendor/discounts/", {
      data: data,
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      if (res.type == "success") setRefresh(!refresh);
    });
  };
  const updateDiscount = (data) => {
    useUPDATE("vendor/discounts/", {
      data: data,
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      if (res.type == "success") setRefresh(!refresh);
    });
  };

  const deleteDiscount = (id) => {
    useDELETE("vendor/discounts/", {
      data: { id: id },
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      if (res.type == "success") setRefresh(!refresh);
    });
  };

  return { discounts, createDiscount, updateDiscount, deleteDiscount };
};
