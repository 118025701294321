import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useGET, usePOST } from "./utils";

export const useOrders = () => {
  const { handleNotification, user, trackerID } = useStateContext();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const updateOrder = (productID, status) => {
    setLoading(true);
    usePOST("vendor/orders/", {
      data: { status: status, product_id: productID },
      token: user.access,
      trackerID: trackerID,
    }).then((res) => {
      handleNotification(res);
      setLoading(false);
      setRefresh(!refresh);
    });
  };
  useEffect(() => {
    setLoading(true);
    useGET("vendor/orders/", { token: user.access, trackerID: trackerID }).then(
      (res) => {
        if (res.type == "success") setOrders(res.data);
        if (res.type == "error") handleNotification(res);
        setLoading(false);
      }
    );
  }, [refresh]);

  return { updateOrder, orders, loading };
};
