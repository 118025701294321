import { useEffect, useRef } from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";

const Chat = () => {
  const { currentColor, setIsClicked, initialState } = useStateContext();
  const chatRef = useRef();

  useEffect(() => {
    const handleClose = (e) => {
      if (!chatRef.current.contains(e.target)) {
        setIsClicked(initialState);
      }
    };
    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, []);

  return (
    <div
      ref={chatRef}
      className="border border-gray-200 dark:border-gray-900 nav-item absolute right-8 md:right-52 top-12 bg-white dark:bg-[#42464D] p-5 rounded-lg w-96"
    >
      <div className="flex justify-between items-center">
        <div className="flex ">
          <p className="font-semibold text-lg dark:text-gray-200">Messages</p>
          <button
            type="button"
            className="text-white  text-xs rounded p-1 px-2 bg-orange"
          >
            5 New
          </button>
        </div>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div>
        <div className="mt-5">
          <Link
            onClick={() => setIsClicked(initialState)}
            style={{ backgroundColor: currentColor }}
            className="block text-center rounded-lg p-3 w-full hover:drop-shadow-xl text-white dark:hover:bg-secondary-dark-bg"
            to="/chat/"
          >
            See all messages
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Chat;
